export const headerWeb = {
  logo: {
    img: null,
  },
  links: [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "How we do it",
      link: "/home/how-we-do-it",
    },
    {
      label: "Projects",
      link: "/projects"
    },
    {
      label: "About us",
      link: "/aboutus",
    },
    {
      label: "Blog",
      link: "https://blog.cavepot.com/",
    },
    {
      label: "Our Lab",
      link: "https://www.cavepotlab.com/",
    },
    {
      label: "Contact us",
      link: "https://cavepot.com/contact",
    },
  ],
  backgroundColor: "#5a4e7d",
  textColor: "white",
  contactBtnColor: "#5a4e7d",
  contactBtnColorText: "white",
};
